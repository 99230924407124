@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 640px) {
  .icons {
    font-size: 10px !important; 
  }
}

@media only screen and (min-width: 768px) {
  .icons {
    font-size: 20px !important ;
  }
}


@media only screen and (min-width: 1024px) {
  .icons {
    font-size: 30px !important;
  }
}
